<template>
	<div class="teacher">
		<list-template
        :loading="loading"
			:total="total"
			:table-data="tableData"
			:table-config="tableConfig"
			:search-config="searchConfig"
			:current-page="page"
			@onSearch="onSearch"
			@onReset="search = null"
			@onChangePage="changeCurrentPage"
			@onHandle="tableHandle"
			hasAdd
			@onAdd="onAdd"
		></list-template>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
		return {
      loading:true,
			// 表格搜索配置
			searchConfig: [
				{
					prop: 'school_id',
					tag: 'select',
					placeholder: '请选择校区',
					options: [],
					value: 'id',
					label: 'name',
					change: (val) => {
						this.$_axios2.get('api/room/room/building', { params: { school_id: val } }).then(res => {
							this.searchConfig[1].options = res.data.data;
						});
					}
				}, {
					prop: 'building_id',
					tag: 'select',
					placeholder: '请选择楼宇',
					options: [],
					value: 'id',
					label: 'name',
				}, {
					prop: 'name',
					placeholder: '请输入房间名称',
				},
			],
			// 表格配置
			tableConfig: [
				{
					prop: 'room_name',
					label: '房间名称',
				}, {
					prop: 'room_no',
					label: '房间编号',
				}, {
					prop: 'school_name',
					label: '所属校区',
				}, {
					prop: 'building_name',
					label: '所属楼宇',
				}, {
					prop: 'floor',
					label: '所属楼层',
				}, {
					prop: 'room_type',
					label: '房间类型',
				}, {
					prop: 'room_num',
					label: '容纳人数',
				}, {
					label: '操作',
					handle: true,
					width: 150,
          buttons: [{ type:"view", text:"详情" }, { type:"edit", text:"编辑" }, { type:"delete", text:"删除" }]
				},
			],
			search: null,
			total: 0,
			// 表格中的数据
			tableData: [],
			// 删除宿舍的id
			dormitoryId: '',
		};
	},
	computed: {
		...mapState(['page']),
	},
	mounted() {
		this.$store.commit('setPage', 1);
		this.getData();

		//	获取校区
		this.$_axios2.get('api/room/room/school').then(res => {
			this.searchConfig[0].options = res.data.data;
		});
	},
	activated() {
		this.getData();
	},
	methods: {
		// 分页被更改
		changeCurrentPage(e) {
			this.$store.commit('setPage', e);
			this.getData();
		},
		/**
		 * 获取数据
		 */
		getData() {
			let { search, page } = this;
      this.loading = true;
			this.$_axios2.get('api/room/room/list', { params: { ...search, page } }).then(res => {
				this.tableData = res.data.data.list;
				this.total = res.data.data.page.total;
			}).finally(()=>this.loading = false);
		},
		// 新增按钮
		onAdd() {
			this.$router.push('./add');
		},
		// 搜索按钮被点击
		onSearch(val) {
			this.$store.commit('setPage', 1);
			this.search = val;
			this.getData();
		},
		// 表格后面操作被点击
		tableHandle(row, handle) {
      const { type } = handle
			if (type === 'view') {
				this.$router.push('./details?id=' + row.id);
			}
			if (type === 'edit') {
				this.$router.push('./edit?id=' + row.id);
			}
			if (type === 'delete') {
				this.$confirm('删除房间信息?', {
					confirmButtonText: '删除',
					cancelButtonText: '点错了',
					type: 'warning',
				}).then(() => {
					this.$_axios2.get('api/room/room/del?id=' + row.id).then((res)=>{
            if (res.data.status===0) {
              this.$message.success('删除成功')
              this.getData();
            } else this.$message.error(res.data.msg)
					});
				}).catch(() => {});
			}
		},
	},
};
</script>

<style scoped lang="scss">
</style>
